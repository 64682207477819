import { useState, useEffect, useMemo, useRef } from 'react';
import {
    useLazyLoadQuery,
    graphql,
    useRelayEnvironment,
    fetchQuery,
    FetchPolicy,
    useFragment,
} from 'react-relay';
import { Subscription } from 'relay-runtime';
import { filterNulls } from 'dibs-ts-utils/exports/filterNulls';

import { usePhotosLikeDataRefetchQuery } from './__generated__/usePhotosLikeDataRefetchQuery.graphql';
import { usePhotosLikeDataRefetch_photos$key } from './__generated__/usePhotosLikeDataRefetch_photos.graphql';

export type PhotoLike = {
    photoLikeId: string | null;
};

type PhotoLikeDataMap = Map<string, PhotoLike>;

type UsePhotosLikeDataResult = {
    photoLikeDataMap: PhotoLikeDataMap;
    isRefetching: boolean;
};

const usePhotosLikeQuery = graphql`
    query usePhotosLikeDataRefetchQuery(
        $photoIds: [String!]
        $userId: String
        $hasUserId: Boolean = false
    ) {
        viewer {
            photosSearchBrowseQuery(photoIds: $photoIds) @include(if: $hasUserId) {
                edges {
                    node {
                        serviceId
                        photoLike {
                            photoLikeId(userId: $userId)
                        }
                    }
                }
            }
        }
    }
`;

export const usePhotosLikeDataRefetch = ({
    photosRef,
    shouldFetch = true,
    userId,
}: {
    photosRef: usePhotosLikeDataRefetch_photos$key | null | undefined;
    shouldFetch?: boolean;
    userId: string;
}): UsePhotosLikeDataResult => {
    const photos = useFragment(
        graphql`
            fragment usePhotosLikeDataRefetch_photos on PhotosSearchBrowsePhoto
            @relay(plural: true) {
                serviceId
            }
        `,
        photosRef
    );
    const environment = useRelayEnvironment();
    const [isRefetching, setIsRefetching] = useState(false);
    const photoIds = useMemo(
        () => photos?.map(photo => photo && photo.serviceId).filter(filterNulls),
        [photos]
    );
    const [queryVariables, setQueryVariables] = useState({
        photoIds,
        userId,
        hasUserId: !!userId && shouldFetch,
    });
    const [fetchPolicy, setFetchPolicy] = useState<FetchPolicy>('store-or-network');

    // useLazyLoadQuery is needed so updater function in PhotoHeart component could update photoLikeId
    const data = useLazyLoadQuery<usePhotosLikeDataRefetchQuery>(
        usePhotosLikeQuery,
        queryVariables,
        { fetchPolicy }
    );

    const subscriptionRef = useRef<Subscription | null>(null);

    useEffect(() => {
        if (shouldFetch && !!userId) {
            const updatedVariables = {
                photoIds,
                userId,
                hasUserId: true,
            };
            setIsRefetching(true);
            // a workaround to avoid Suspense when refetching query
            // https://relay.dev/docs/guided-tour/refetching/refetching-queries-with-different-data/#if-you-need-to-avoid-suspense-1
            subscriptionRef.current = fetchQuery(
                environment,
                usePhotosLikeQuery,
                updatedVariables
            ).subscribe({
                complete: () => {
                    setIsRefetching(false);
                    setQueryVariables(updatedVariables);
                    setFetchPolicy('store-only');
                },
            });
        }
        return () => {
            if (subscriptionRef.current) {
                subscriptionRef.current.unsubscribe();
            }
        };
    }, [userId, environment, photoIds, shouldFetch]);

    const photoLikeDataMap = useMemo(() => {
        const edges = data?.viewer?.photosSearchBrowseQuery?.edges || [];
        return edges.reduce((acc, edge) => {
            const { photoLike, serviceId: photoId } = edge?.node || {};
            if (photoId && photoLike) {
                acc.set(photoId, {
                    photoLikeId: photoLike.photoLikeId || null,
                });
            }
            return acc;
        }, new Map() as PhotoLikeDataMap);
    }, [data]);

    return { photoLikeDataMap, isRefetching };
};
