/**
 * @generated SignedSource<<de6e7107683385d45dad292208cb65db>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Currency = "AUD" | "CAD" | "CHF" | "DKK" | "EUR" | "GBP" | "JPY" | "MXN" | "NOK" | "SEK" | "USD" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type SbSharedFeaturedDeal_itemSearch$data = {
  readonly featuredDeal: {
    readonly convertedAmountList: ReadonlyArray<{
      readonly amount: number | null;
      readonly currency: Currency | null;
      readonly linkData: {
        readonly " $fragmentSpreads": FragmentRefs<"SeoLink_linkData">;
      };
    } | null> | null;
    readonly imageUrl: string | null;
    readonly vertical: string | null;
  } | null;
  readonly " $fragmentType": "SbSharedFeaturedDeal_itemSearch";
};
export type SbSharedFeaturedDeal_itemSearch$key = {
  readonly " $data"?: SbSharedFeaturedDeal_itemSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedFeaturedDeal_itemSearch">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbSharedFeaturedDeal_itemSearch",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "FeaturedDeal",
      "kind": "LinkedField",
      "name": "featuredDeal",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "imageUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "vertical",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ConvertedAmountList",
          "kind": "LinkedField",
          "name": "convertedAmountList",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "amount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "currency",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "LinkData",
              "kind": "LinkedField",
              "name": "linkData",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "SeoLink_linkData"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ItemSearchQueryConnection",
  "abstractKey": null
};

(node as any).hash = "02d44fbeba3671058590f1320e26aa94";

export default node;
