import { FunctionComponent, MouseEvent } from 'react';
import { Badge } from 'dibs-elements/exports/Badge';
import { SeoLink } from 'dibs-elements/exports/SeoLink';
import { FormattedMessage } from 'dibs-react-intl';
import { Link } from 'dibs-elements/exports/Link';
import { trackEvent } from 'dibs-tracking';
import styles from './SharedRelatedItems.scss';
import classnames from 'classnames';

import { BuySharedRelatedPages_buyPage$data } from '../../buy/BuySharedRelatedPages/__generated__/BuySharedRelatedPages_buyPage.graphql';
import { SbSharedRelatedSearches_itemSearch$data } from '../../finding/SbSharedRelatedSearches/__generated__/SbSharedRelatedSearches_itemSearch.graphql';

type Props = {
    relatedItems:
        | BuySharedRelatedPages_buyPage$data['relatedBuyPages']
        | SbSharedRelatedSearches_itemSearch$data['relatedSearches'];
    popularSearchesLink?: BuySharedRelatedPages_buyPage$data['popularSearchesLink'];
    onClick?: (index: number, event: MouseEvent) => void;
    isFullWidth?: boolean | null;
    isLinkable?: boolean;
    dataTn?: string;
    wrapperClassName?: string | null;
};

export const SharedRelatedItems: FunctionComponent<Props> = ({
    relatedItems,
    popularSearchesLink,
    onClick = () => {},
    isFullWidth,
    isLinkable = true,
    dataTn,
    wrapperClassName,
}) => {
    if (!relatedItems) {
        return null;
    }

    const wrapperClasses = classnames(styles.wrapper, wrapperClassName, {
        [styles.fullWidthWrapper]: isFullWidth,
    });
    const innerWrapperClasses = classnames(styles.innerWrapper, {
        [styles.fullWidthInnerWrapper]: isFullWidth,
    });
    const linkClassName = classnames(styles.link, styles.specify);
    const { text: popularSearchesCategory, path: popularSearchesUrl } = popularSearchesLink || {};

    return (
        <div className={wrapperClasses}>
            <div className={innerWrapperClasses}>
                {relatedItems.map((item, index) => {
                    const { url, anchorText } = item || {};
                    if (url && anchorText) {
                        return (
                            <SeoLink
                                key={url}
                                linkData={{ path: url, isLinkable }}
                                onClick={event => onClick(index, event)}
                                dataTn={dataTn}
                                className={linkClassName}
                            >
                                <Badge type="pill" size="medium-regular" interactive>
                                    {anchorText}
                                </Badge>
                            </SeoLink>
                        );
                    }
                    return null;
                })}
                {popularSearchesCategory && popularSearchesUrl && (
                    <Link
                        href={popularSearchesUrl}
                        onClick={() =>
                            trackEvent({
                                category: 'promo interaction',
                                action: 'mwtb popular searches link click - top',
                                label: `View All Popular ${popularSearchesCategory} Searches`,
                            })
                        }
                        dataTn="popular-searches-link"
                        className={linkClassName}
                    >
                        <Badge
                            type="pill"
                            size="medium-regular"
                            backgroundColor="buyer-tertiary"
                            interactive
                        >
                            <FormattedMessage
                                id="SharedRelatedItemsPopularSearchesLink.linkText"
                                defaultMessage="More Popular Searches"
                            />
                        </Badge>
                    </Link>
                )}
            </div>
        </div>
    );
};
