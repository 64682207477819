import { FC, Suspense } from 'react';
import { useFragment, graphql } from 'react-relay';
import PhotoOverlayItem from 'dibs-interiors-photos/exports/PhotoOverlayItem';
import { SbSharedPhotoHeartLazy } from '../SbSharedPhotoHeartLazy';

import styles from './SbSharedInteriorsHeartWrapper.scss';

import type { SbSharedInteriorsHeartWrapper_photo$key } from './__generated__/SbSharedInteriorsHeartWrapper_photo.graphql';

type Props = {
    onPhotoLikeAdded: () => void;
    photoLikeId?: string | null;
    photo: SbSharedInteriorsHeartWrapper_photo$key;
};

const SbSharedInteriorsHeartWrapper: FC<Props> = ({
    onPhotoLikeAdded,
    photoLikeId,
    photo: photoRef,
}) => {
    const photo = useFragment(
        graphql`
            fragment SbSharedInteriorsHeartWrapper_photo on PhotosSearchBrowsePhoto {
                id
                serviceId
                photoLike {
                    totalPhotoLikes
                }
            }
        `,
        photoRef
    );

    const photoNodeId = photo?.id || '';
    const photoId = photo?.serviceId || '';
    const totalPhotoLikes = photo?.photoLike?.totalPhotoLikes;

    return (
        <Suspense fallback="">
            <PhotoOverlayItem>
                <div className={styles.heartWrapper}>
                    <SbSharedPhotoHeartLazy
                        photoNodeId={photoNodeId}
                        photoId={photoId}
                        photoLikeId={photoLikeId}
                        onPhotoLikeAdded={onPhotoLikeAdded}
                        tooltipDirection="bottom"
                        totalPhotoLikes={totalPhotoLikes}
                    />
                </div>
            </PhotoOverlayItem>
        </Suspense>
    );
};

export default SbSharedInteriorsHeartWrapper;
