/**
 * @generated SignedSource<<ec40177729038dbbf025813eccdb21a4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbRespCategoryTiles_itemSearch$data = {
  readonly categoryTiles: ReadonlyArray<{
    readonly displayName: string | null;
    readonly image: string | null;
    readonly url: string | null;
  } | null> | null;
  readonly displayUriRef: string | null;
  readonly isL1LandingPage: boolean | null;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedCategoryTilesTracking_itemSearch">;
  readonly " $fragmentType": "SbRespCategoryTiles_itemSearch";
};
export type SbRespCategoryTiles_itemSearch$key = {
  readonly " $data"?: SbRespCategoryTiles_itemSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbRespCategoryTiles_itemSearch">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbRespCategoryTiles_itemSearch",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedCategoryTilesTracking_itemSearch"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayUriRef",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isL1LandingPage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SearchBrowseCategoryTiles",
      "kind": "LinkedField",
      "name": "categoryTiles",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "image",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ItemSearchQueryConnection",
  "abstractKey": null
};

(node as any).hash = "05c636b2a1125fbb5d04c88a26bbe2e8";

export default node;
