import { FC, ReactNode } from 'react';
import classNames from 'classnames';
import { getSrcsetString } from 'dibs-image-utils/exports/srcSet';

// styles
import styles from './SbSharedL1FeaturedTile.scss';

type Props = {
    mainImage?: string | null;
    heading?: ReactNode;
    subHeading?: ReactNode;
    fullWidth?: boolean;
    children?: FC<{ className: string; children: ReactNode }>;
};

export const SbSharedL1FeaturedTile: FC<Props> = ({
    mainImage,
    heading,
    subHeading,
    fullWidth,
    children,
}) => {
    if (!mainImage) {
        return null;
    }

    const tile = (
        <>
            <div
                className={classNames(styles.imageContainer, {
                    [styles.fullWidth]: fullWidth,
                })}
            >
                <img
                    alt=""
                    className={styles.image}
                    sizes={`(max-width: 768px) 100vw, ${fullWidth ? '620' : '1316'}px`}
                    srcSet={getSrcsetString(mainImage)}
                    src={mainImage}
                />
            </div>
            <span className={styles.heading}>{heading}</span>
            <span className={styles.subHeading}>{subHeading}</span>
        </>
    );

    return children ? (
        children({
            className: styles.container,
            children: tile,
        })
    ) : (
        <div className={styles.container}>{tile}</div>
    );
};
