import get from 'lodash.get';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'dibs-react-intl';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { pageTypeConstants as pageTypes } from '../../constants/pageTypeConstants';
import { Link } from 'dibs-elements/exports/Link';
import {
    trackSearchSuggestionClick,
    encodeStaticSearchSuggestionsTrackingData,
    ATTRIBUTE_SEARCH_SUGGESTION_TRACKING,
} from './sbSharedSearchSuggestionsTracking';

// styles
import styles from './sbSharedSearchSuggestions.scss';

function SbSharedSearchSuggestionsComponent(props) {
    const { itemSearch } = props;
    const { searchTerm } = itemSearch;
    const suggestions = get(props, 'itemSearch.searchCorrections.suggestions');

    if (itemSearch.pageType !== pageTypes.SEARCH || !suggestions || !searchTerm) {
        return null;
    }

    return (
        <ul className={props.className} data-tn="search-suggestions-wrapper">
            <li className={styles.suggestion} data-tn="search-suggestions-title">
                <FormattedMessage
                    id="sb.SbSharedSearchSuggestions.suggestionText"
                    defaultMessage="Did you mean"
                />
            </li>
            {suggestions.map(({ name, uri }) => (
                <li
                    key={uri}
                    className={styles.suggestion}
                    data-tn="search-suggestion"
                    {...{
                        [ATTRIBUTE_SEARCH_SUGGESTION_TRACKING]:
                            encodeStaticSearchSuggestionsTrackingData({ searchTerm, name }),
                    }}
                >
                    <Link href={uri} onClick={() => trackSearchSuggestionClick(searchTerm, name)}>
                        <span>{name}</span>
                    </Link>
                </li>
            ))}
        </ul>
    );
}

SbSharedSearchSuggestionsComponent.propTypes = {
    className: PropTypes.string,
    itemSearch: PropTypes.shape({
        pageType: PropTypes.string.isRequired,
        searchTerm: PropTypes.string,
        suggestions: PropTypes.shape({
            name: PropTypes.string.isRequired,
            uri: PropTypes.string.isRequired,
        }),
    }),
};

export const SbSharedSearchSuggestions = createFragmentContainer(
    SbSharedSearchSuggestionsComponent,
    {
        itemSearch: graphql`
            fragment SbSharedSearchSuggestions_itemSearch on ItemSearchQueryConnection {
                pageType
                searchTerm
                searchCorrections {
                    suggestions {
                        name
                        uri
                    }
                }
            }
        `,
    }
);
