/* Queries */
import { sbRespRoot } from '../../queries/sbRespRoot';

/* Reducers */
import { defaultReducers, actionSanitizer } from '../../reducers/reducerUtils';
import { sbSharedReducers } from '../../reducers/sbSharedReducers';
import { sbRespNotificationsReducer as sharedNotificationsReducer } from '../../reducers/sbRespNotificationsReducer';

/* Components */
import { SbRespLayout } from '../../finding/SbRespLayout/SbRespLayout';

/* Helpers */
import { createEntry } from '../createEntry';
import { initializeDBL } from 'dibs-buyer-layout/src/entries';
import { initSharedDatadogRum, sbTypes } from 'dibs-buyer-layout/exports/initSharedDatadogRum';
import { PAGE_TYPE } from 'dibs-constants/exports/pageTypes';
import SV from 'server-vars';

/* Notifications */
import { sbRespNotifications } from '../../finding/SbRespNotifications/sbRespNotifications';

/**
 * DBL has a different environment that's created in initializeDBL.
 */
const options = {
    notifications: sbRespNotifications,
    reducers: {
        ...defaultReducers,
        ...sbSharedReducers,
        sharedNotificationsReducer,
    },
    state: SV.get('storeState'),
    actionSanitizer,
};
const isSearch = (SV.get('relay.variables.uriRef') || '').startsWith('/search/');
initSharedDatadogRum({
    page: PAGE_TYPE.BROWSE,
    pageType: isSearch ? sbTypes.search : sbTypes.browse,
    device: 'resp',
});
initializeDBL(options).then(({ store, queue, actions }) => {
    createEntry({
        Container: SbRespLayout,
        store,
        query: sbRespRoot,
        containerId: 'page',
        queue,
        actions,
    });
});
