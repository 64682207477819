/**
 * @generated SignedSource<<a16dfbef8a61cec66a4e2819037a1696>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbRespComponents_user$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SbRespContentModuleSelector_user" | "SbRespFollowSearchBanner_user" | "SbRespInteriorsWordpressArticlesWrapper_user" | "SbRespMainContent_user" | "SbRespNullSearchResults_user" | "SbSharedPageBreadcrumbs_user" | "SbSharedTitle_user" | "SharedFollowTopButton_user" | "SharedRecentSales_user" | "SharedSaveSearchContext_user" | "SharedYMAL_user" | "TradePromo_user">;
  readonly " $fragmentType": "SbRespComponents_user";
};
export type SbRespComponents_user$key = {
  readonly " $data"?: SbRespComponents_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbRespComponents_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "isTrade"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbRespComponents_user",
  "selections": [
    {
      "condition": "isTrade",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "TradePromo_user"
        }
      ]
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbRespNullSearchResults_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbRespFollowSearchBanner_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SharedFollowTopButton_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedTitle_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedPageBreadcrumbs_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SharedSaveSearchContext_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbRespMainContent_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbRespInteriorsWordpressArticlesWrapper_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SharedRecentSales_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SharedYMAL_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbRespContentModuleSelector_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "67bc45af4ede946d346701c7b5b09663";

export default node;
