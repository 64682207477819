import { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { trackEcommerce, eventNameConstants } from 'dibs-tracking';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import get from 'lodash.get';
import { Link } from 'dibs-elements/exports/Link';

import { getCategories } from '../../utils/categoryHelpers';

import { SbSharedLink } from '../SbSharedLink/SbSharedLink';

import styles from './SbSharedPriceTile.scss';

const getPageLevel = ({ appliedFilters }) => {
    const categories = getCategories(appliedFilters);
    return categories.length;
};

export class SbSharedPriceTileComponent extends Component {
    constructor(props) {
        super(props);
        this.getPromotionTrackingObject = this.getPromotionTrackingObject.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        trackEcommerce({
            type: 'promotionImpression',
            eventName: eventNameConstants.EVENT_VIEW_PROMOTION,
            promotions: [this.getPromotionTrackingObject()],
        });
    }

    getPromotionTrackingObject() {
        const { position, promoLabel, itemSearch } = this.props;

        return {
            creative: get(itemSearch, 'meta.header'),
            id: `L${getPageLevel(itemSearch)}|${promoLabel}`,
            name: `L${getPageLevel(itemSearch)}-Trade Tiles`,
            position,
        };
    }

    handleClick() {
        trackEcommerce({
            type: 'promoClick',
            eventName: eventNameConstants.EVENT_SELECT_PROMOTION,
            promotions: [this.getPromotionTrackingObject()],
        });

        if (typeof this.props.onClick === 'function') {
            this.props.onClick();
        }
    }

    render() {
        const {
            dataTn = 'price-tile',
            displayName,
            hoverMessage,
            IconComponent,
            image,
            isPriceBucket,
            isSbUrl,
            url,
        } = this.props;

        let innerContent;
        if (isPriceBucket) {
            innerContent = (
                <div className={styles.imageContainer}>
                    <img className={styles.image} src={image} alt="price" />
                    <div className={styles.priceBucketTileOverlay} />
                    <div className={styles.priceBucketTileHoverMessage}>{hoverMessage}</div>
                </div>
            );
        } else {
            const bgImageStyle = { backgroundImage: `url(${image})` };
            innerContent = (
                <div className={styles.imageContainer}>
                    <div className={styles.bgImage} style={bgImageStyle} />
                    <div className={styles.tileOverlay} />
                    <div className={styles.tileHoverMessage}>
                        <IconComponent className={styles.icon} />
                        {hoverMessage}
                    </div>
                </div>
            );
        }

        const content = (
            <Fragment>
                {innerContent}
                <div data-tn="price-tile-name" className={styles.displayName}>
                    {displayName}
                </div>
            </Fragment>
        );

        const linkProps = {
            className: styles.tile,
            href: url,
            onClick: this.handleClick,
        };

        if (isSbUrl) {
            return (
                <SbSharedLink {...linkProps} dataTn={dataTn}>
                    {content}
                </SbSharedLink>
            );
        }

        return (
            <Link {...linkProps} dataTn={dataTn}>
                {content}
            </Link>
        );
    }
}

SbSharedPriceTileComponent.propTypes = {
    dataTn: PropTypes.string,
    displayName: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    hoverMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    IconComponent: PropTypes.func,
    image: PropTypes.string,
    isPriceBucket: PropTypes.bool,
    onClick: PropTypes.func,
    position: PropTypes.number.isRequired,
    promoLabel: PropTypes.string.isRequired,
    itemSearch: PropTypes.object,
    isSbUrl: PropTypes.bool,
    url: PropTypes.string,
};

SbSharedPriceTileComponent.defaultProps = {
    isSbUrl: true,
};

export const SbSharedPriceTile = createFragmentContainer(SbSharedPriceTileComponent, {
    itemSearch: graphql`
        fragment SbSharedPriceTile_itemSearch on ItemSearchQueryConnection {
            meta {
                header
            }
            appliedFilters {
                name
                values {
                    urlLabel
                }
            }
        }
    `,
});
