import { FC } from 'react';
import LazyImage from 'dibs-react-lazy-image';
import { getSrcsetString, getSrcsetUrl } from 'dibs-image-utils/exports/srcSet';

const LAZY_IMAGE_OFFSET = 200;
const DEFAULT_IMAGE_WIDTH = 800;

import styles from './SbRespInteriorsLazyLoadImage.scss';

type Props = {
    imgClass: string;
    lazyLoadClass?: string;
    offsetHorizontal?: number;
    offsetVertical?: number;
    photo: {
        path: string | null;
        name: string | null;
    } | null;
    onImageLoaded?: () => void;
};

export const SbRespInteriorsLazyLoadImage: FC<Props> = ({
    imgClass,
    lazyLoadClass,
    photo,
    offsetHorizontal = LAZY_IMAGE_OFFSET,
    offsetVertical = LAZY_IMAGE_OFFSET,
    onImageLoaded = () => {},
}) => {
    if (!photo) {
        return null;
    }

    const photoPath = photo.path || '';
    return (
        <LazyImage
            className={lazyLoadClass}
            offsetVertical={offsetVertical}
            offsetHorizontal={offsetHorizontal}
            onImageLoaded={onImageLoaded}
            placeholderClass={styles.placeholderClass}
        >
            <img
                className={imgClass}
                src={getSrcsetUrl(photoPath, DEFAULT_IMAGE_WIDTH)}
                srcSet={getSrcsetString(photoPath)}
                alt={photo.name || ''}
            />
        </LazyImage>
    );
};
