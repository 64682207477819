/**
 * @generated SignedSource<<e2d3443b82440f37512820edc16674de>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbRespInteriorsPhotoGallery_interiorPhotos$data = {
  readonly photos: ReadonlyArray<{
    readonly id: string;
    readonly name: string | null;
    readonly path: string | null;
    readonly serviceId: string | null;
    readonly url: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"SbSharedInteriorsHeartWrapper_photo" | "usePhotosLikeDataRefetch_photos">;
  } | null> | null;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedInteriorsBrowseMore_interiorPhotos" | "SbSharedInteriorsPhotoRelatedSearchLinks_interiorPhotos" | "interiorsModuleHelpers_interiorPhotos">;
  readonly " $fragmentType": "SbRespInteriorsPhotoGallery_interiorPhotos";
};
export type SbRespInteriorsPhotoGallery_interiorPhotos$key = {
  readonly " $data"?: SbRespInteriorsPhotoGallery_interiorPhotos$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbRespInteriorsPhotoGallery_interiorPhotos">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbRespInteriorsPhotoGallery_interiorPhotos",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedInteriorsBrowseMore_interiorPhotos"
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "interiorsModuleHelpers_interiorPhotos",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "InteriorPhotosModulePhotoSearchParam",
          "kind": "LinkedField",
          "name": "photoSearchParam",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedInteriorsPhotoRelatedSearchLinks_interiorPhotos"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PhotosSearchBrowsePhoto",
      "kind": "LinkedField",
      "name": "photos",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "path",
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "serviceId",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "usePhotosLikeDataRefetch_photos"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SbSharedInteriorsHeartWrapper_photo"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "SearchBrowseInteriorPhotosModule",
  "abstractKey": null
};
})();

(node as any).hash = "be0b09511bb6f84856ea1951ddd0b7ad";

export default node;
