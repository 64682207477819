/**
 * @generated SignedSource<<5e392bbc952e88d45f2796b3da3d6040>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type interiorsModuleHelpers_interiorPhotos$data = {
  readonly photoSearchParam: {
    readonly name: string | null;
  } | null;
  readonly " $fragmentType": "interiorsModuleHelpers_interiorPhotos";
};
export type interiorsModuleHelpers_interiorPhotos$key = {
  readonly " $data"?: interiorsModuleHelpers_interiorPhotos$data;
  readonly " $fragmentSpreads": FragmentRefs<"interiorsModuleHelpers_interiorPhotos">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "interiorsModuleHelpers_interiorPhotos"
};

(node as any).hash = "983cc22422edafbc44eb8baaeff21fa7";

export default node;
