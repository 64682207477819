import dibsCss from 'dibs-css';
import classnames from 'classnames';
import { FunctionComponent } from 'react';

type ChairLampProps = {
    className?: string;
};

const ChairLamp: FunctionComponent<ChairLampProps> = props => {
    return (
        <svg
            className={classnames(dibsCss.dibsIcon, props.className)}
            data-tn={'chair-lamp'}
            x="0px"
            y="0px"
            viewBox="0 0 250 250"
        >
            <path
                d="M137.5 145.4c-2.6-1.8-6.1-2.7-10.4-2.7-6.6 0-13.2 2-14.5 2.3-4.2 1.2-6.1 1.3-6.1 1.3h-.3c-1.7 0-2.9-.4-3.5-1.1-.9-1-.8-3-.6-3.6l.1-.4c1.4-15.8 14.5-35 14.7-35.2l.2-.2c6.7-11.6 2.1-17.7.6-19.2-3.2-3.8-8.4-5.9-15.2-5.9-5.4 0-9.8 1.2-9.8 1.2l-14.2 2.9c-4 .5-8 .8-11.8.8-15.3 0-23.4-4.2-24.9-5-2.4-1.4-4.7-2.2-6.8-2.2-6.8 0-10.3 6.7-11.3 10.7-4.8 14.8-2.9 37.7-2.9 37.9 2 36.3 10.3 50 12 52.4 14.5 23.5 38.7 28.1 45.4 28.9v15.4l-17-4.9-2.1 6.8 8.9 2.6-14.4 4.7 2.2 6.8 25.6-8.3 25.6 8.3 2.2-6.8-14.4-4.7 8.9-2.6-1.9-6.9-16.6 4.8v-14.9h1.3c19 0 31.2-7.5 38.2-13.9 6.7-6.2 9.8-12.3 10.6-14.1l4-8.9.1-.2c7.1-19-1-25.4-1.9-26.1zm-99.6-30c-.6 1.4-3.4 8.8-3.4 18.5 0 .1-.6 9.8 4.2 19.4l.1.2c.7 1.2 3.5 5.7 7.9 6.6 2.3.4 4.4-.1 6.4-1.6l.3-.2.1-.1c2.6-2.6 4.6-4.4 5.5-4.9.1 0 5.2-3.5 9.3 1.2 2.6 4.1 5.2 9.1 7.3 14 1.3 2.9 8.5 17.8 21.6 21.1 2.3.6 7.5.7 10 .7h1.3c4.8-.1 9.5-1.3 13.9-3.3-6.8 7.4-18 14.7-36.3 14.7-1.8 0-3.9-.1-6.4-.3-.3 0-26.9-2.2-41.4-25.8l-.1-.2v-.1c-.9-1.3-8.7-14-10.6-48.7v-.2c-.3-3.8-1.4-23.2 2.6-35.1l.1-.3c.3-1.2 1.9-5.3 4.4-5.3.8 0 1.7.3 3 1 0 0 1.7 1.1 5.3 2.5.2 4.6-.3 15.3-5.1 26.2zm70.3 67.8c-2.8.1-8.1-.2-9.4-.5-4.6-1.1-8.2-4.5-10.8-7.5 17.1-.7 31.7-.3 38.9-.1-2.2 2.2-4.8 4.1-7.6 5.4-3.4 1.7-7.2 2.6-11.1 2.7zM82 165.6c-3.5-8.1-7-13.6-8-15.2l-.2-.3c-6-7.3-14.6-5.6-18.9-2.8-1.7 1.1-4.4 3.7-5.9 5.1l-.6.6c-.2.2-.4.2-.4.2-.8-.1-2.3-1.8-3-3.1-3.6-7.4-3.5-15-3.5-15.9v-.1c0-8.5 2.5-15 2.8-15.8l.1-.1c4.7-10.7 5.7-21.1 5.8-27.1 5.2 1.1 10.6 1.7 16.3 1.7 4.3 0 8.6-.2 12.9-.8L94 89l.3-.1c2.7-.6 5.5-1 8-1 3.2 0 7.6.6 9.6 3.3l.2.3.3.2c.3.3 2.4 3.1-1.8 10.4-.6.8-14.2 20.7-15.8 38.3-.2.9-.8 5.6 2.1 9.3 2 2.5 5 3.7 9 3.7h.3c.3 0 2.9 0 8.2-1.6.1 0 .1 0 .2-.1 2.6-.7 7.6-2 12.2-2 2.7 0 4.9.4 6.2 1.3.4.4 4.1 4.7-.5 17.2-5.5-.3-26-1.1-49.4.1-.7-1.2-1.1-2.1-1.2-2.4l.1-.3z"
                className="st0"
            />
            <path
                d="M219.6 18.3c-.5-2.7-1.5-5-3.2-6.5-1.7-1.6-3.8-2.4-6.3-2.4h-52.8c-5.1 0-9.8 4-10.4 9l-10.7 59.5v.6c0 5.3 4.2 9.5 9.5 9.5H180v145h-19.9v7.3h47V233h-19.8V88h34.4c5.2 0 9.5-4.3 9.5-9.5v-.3l-11.6-59.9zm-65.4 1.3v-.2c.1-.6.5-1.3 1.1-1.8.6-.5 1.4-.8 2.2-.8h52.8c.3 0 .7 0 1.1.3.6.4 1 1.3 1.2 2.7l11.6 58.9c-.3 1.1-1.3 2-2.4 2h-76.1c-1.1 0-2-.8-2.2-1.9l10.7-59.2z"
                className="st0"
            />
        </svg>
    );
};

export default ChairLamp;
