/**
 * @generated SignedSource<<431f1606b05c728981803fc12b0b1191>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbRespTradeFreeShippingPromoTile_viewer$data = {
  readonly shippingFilters?: ReadonlyArray<{
    readonly name: string | null;
    readonly values: ReadonlyArray<{
      readonly displayName: string | null;
      readonly linkReference: string | null;
      readonly urlLabel: string | null;
    } | null> | null;
  } | null> | null;
  readonly " $fragmentType": "SbRespTradeFreeShippingPromoTile_viewer";
};
export type SbRespTradeFreeShippingPromoTile_viewer$key = {
  readonly " $data"?: SbRespTradeFreeShippingPromoTile_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbRespTradeFreeShippingPromoTile_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "isClient"
    },
    {
      "kind": "RootArgument",
      "name": "uriRef"
    },
    {
      "kind": "RootArgument",
      "name": "userCountryCode"
    },
    {
      "kind": "RootArgument",
      "name": "userZipCode"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbRespTradeFreeShippingPromoTile_viewer",
  "selections": [
    {
      "condition": "isClient",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "countryCode",
              "variableName": "userCountryCode"
            },
            {
              "kind": "Variable",
              "name": "uriRef",
              "variableName": "uriRef"
            },
            {
              "kind": "Variable",
              "name": "zipCode",
              "variableName": "userZipCode"
            }
          ],
          "concreteType": "ShippingFilterType",
          "kind": "LinkedField",
          "name": "shippingFilters",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "FilterValueType",
              "kind": "LinkedField",
              "name": "values",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "urlLabel",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "displayName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "linkReference",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "521b179f33a869745fc9dfb13856809d";

export default node;
