/**
 * @generated SignedSource<<a3c990f7f652f725739b495d7b623b10>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbRespInteriorsModule_itemSearch$data = {
  readonly interiorPhotos: {
    readonly photos: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"SbRespInteriorsPhotoCarousel_photos">;
    } | null> | null;
    readonly " $fragmentSpreads": FragmentRefs<"SbRespInteriorsPhotoCarousel_interiorPhotos" | "SbRespInteriorsPhotoGallery_interiorPhotos" | "SbSharedInteriorsPhotoModalRenderer_interiorPhotos" | "interiorsModuleHelpers_interiorPhotos">;
  } | null;
  readonly meta: {
    readonly attributeDisplayName: string | null;
    readonly header: string | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"SbRespInteriorsPhotoCarousel_itemSearch" | "SbRespInteriorsPhotoGallery_itemSearch">;
  readonly " $fragmentType": "SbRespInteriorsModule_itemSearch";
};
export type SbRespInteriorsModule_itemSearch$key = {
  readonly " $data"?: SbRespInteriorsModule_itemSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbRespInteriorsModule_itemSearch">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "interiorPhotosCount"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbRespInteriorsModule_itemSearch",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbRespInteriorsPhotoCarousel_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbRespInteriorsPhotoGallery_itemSearch"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MetaData",
      "kind": "LinkedField",
      "name": "meta",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "attributeDisplayName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "header",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "interiorPhotosCount"
        },
        {
          "kind": "Literal",
          "name": "template",
          "value": "tradeProjectPhoto.search"
        }
      ],
      "concreteType": "SearchBrowseInteriorPhotosModule",
      "kind": "LinkedField",
      "name": "interiorPhotos",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SbSharedInteriorsPhotoModalRenderer_interiorPhotos"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SbRespInteriorsPhotoGallery_interiorPhotos"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SbRespInteriorsPhotoCarousel_interiorPhotos"
        },
        {
          "kind": "InlineDataFragmentSpread",
          "name": "interiorsModuleHelpers_interiorPhotos",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "InteriorPhotosModulePhotoSearchParam",
              "kind": "LinkedField",
              "name": "photoSearchParam",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "args": null,
          "argumentDefinitions": []
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PhotosSearchBrowsePhoto",
          "kind": "LinkedField",
          "name": "photos",
          "plural": true,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SbRespInteriorsPhotoCarousel_photos"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ItemSearchQueryConnection",
  "abstractKey": null
};

(node as any).hash = "641d7d5f959290e5b17e1d97cf692aec";

export default node;
