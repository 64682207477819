import { FC } from 'react';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { FormattedMessage } from 'dibs-react-intl';
import { Button } from 'dibs-elements/exports/Button';
import ChairLamp from 'dibs-icons/exports/legacy/ChairLamp';
import { setSbPhotosBackLink } from 'dibs-interiors-photos/exports/sbInteriorsModuleBackLinkHelpers';
import { trackDesignFeature } from './interiorsModuleHelpers';

import styles from './SbSharedInteriorsBrowseMore.scss';

import { SbSharedInteriorsBrowseMore_interiorPhotos$data } from './__generated__/SbSharedInteriorsBrowseMore_interiorPhotos.graphql';

type Props = {
    interiorPhotos: SbSharedInteriorsBrowseMore_interiorPhotos$data | null | undefined;
};

const SbSharedInteriorsBrowseMoreComponent: FC<Props> = ({ interiorPhotos }) => {
    const photosBrowseLink = interiorPhotos?.photosBrowseLink || '';
    const { linkUrl, displayName } = interiorPhotos?.searchBrowseBackLink || {};

    const setBackLink = (): void => {
        if (linkUrl && displayName) {
            setSbPhotosBackLink({ linkUrl, displayName });
        }
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.content}>
                <ChairLamp className={styles.chairLamp} />
                <div className={styles.seeMore}>
                    <FormattedMessage
                        defaultMessage="Want to see more interiors?"
                        id="abf.SbSharedInteriorsBrowseMore.seeMore"
                    />
                </div>
                <Button
                    className={styles.browseMoreButton}
                    dataTn="browse-photos"
                    href={photosBrowseLink}
                    size="small"
                    onClick={event => {
                        event.stopPropagation();
                        setBackLink();
                        trackDesignFeature({ action: 'browse more cta clicked', interiorPhotos });
                    }}
                >
                    <FormattedMessage
                        defaultMessage="Browse more photos"
                        id="abf.SbSharedInteriorsBrowseMore.browseMorePhotos"
                    />
                </Button>
            </div>
        </div>
    );
};

export const SbSharedInteriorsBrowseMore = createFragmentContainer(
    SbSharedInteriorsBrowseMoreComponent,
    {
        interiorPhotos: graphql`
            fragment SbSharedInteriorsBrowseMore_interiorPhotos on SearchBrowseInteriorPhotosModule {
                ...interiorsModuleHelpers_interiorPhotos
                photosBrowseLink
                searchBrowseBackLink {
                    displayName
                    linkUrl
                }
            }
        `,
    }
);
