import dibsCss from 'dibs-css';
import classnames from 'classnames';
import { FunctionComponent } from 'react';

type ShippingPackageProps = {
    className?: string;
};

const ShippingPackage: FunctionComponent<ShippingPackageProps> = props => {
    return (
        <svg
            className={classnames(dibsCss.dibsIcon, props.className)}
            data-tn={'shipping-package'}
            viewBox="0 0 250 250"
        >
            <path d="M125.5 1.1L2.1 41l10.4 145.6L125 248.1l113.1-60.6 10-145.1L125.5 1.1zM121 236.7L20.2 181.6l-9.3-131L59 74.2V108l26 13.5-.1-34.6 36.1 17.7v132.1zm3.7-139.3L87.2 79l85.9-37.4-27.3-8.9-85.3 33.2-43.4-21.2L125.5 9.5l107.9 36.4-108.7 51.5zm105.8 85L129 236.8V104.3L239.4 52l-8.9 130.4z" />
        </svg>
    );
};
export default ShippingPackage;
