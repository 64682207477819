import { FC } from 'react';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { FormattedMessage } from 'dibs-react-intl';
import { Link } from 'dibs-elements/exports/Link';
import HeadingLevel from 'dibs-controlled-heading/exports/HeadingLevel';
import LazyImage from 'dibs-react-lazy-image';
import { trackArticleClick } from 'dibs-editorial-module/exports/tracking';
import { WORDPRESS_THUMB, WORDPRESS_MEDIUM, WORDPRESS_FULL } from '../../constants/imageConstants';
import { INITIAL_VISIBLE_INTERIOR_ARTICLES } from '../../constants/sbConstants';
import classnames from 'classnames';

import styles from './SbSharedInteriorsArticles.scss';

import { SbSharedInteriorsArticles_editorial$data } from './__generated__/SbSharedInteriorsArticles_editorial.graphql';

const LAZY_IMAGE_VERTICAL_OFFSET = 200;
const getHeaderId = (index: number): string => `wp-article-header-${index}`;

type Props = {
    imgSizes: string;
    editorial: SbSharedInteriorsArticles_editorial$data | null | undefined;
    isExpanded?: boolean;
};

const SbSharedInteriorsArticlesComponent: FC<Props> = ({ imgSizes, editorial, isExpanded }) => {
    const articles = editorial?.articles || [];
    const totalArticlesCount = articles.length;
    if (!totalArticlesCount) {
        return null;
    }
    return (
        <HeadingLevel>
            {Heading => (
                <div
                    className={classnames(styles.wrapper, {
                        [styles.justifyFlexStart]:
                            totalArticlesCount > INITIAL_VISIBLE_INTERIOR_ARTICLES,
                    })}
                >
                    <Heading className={styles.sectionHeader}>
                        <FormattedMessage
                            defaultMessage="From the Magazine"
                            id="abf.SbMobileInteriorsMagazineArticles.header"
                        />
                    </Heading>
                    {articles.map((article, index) => (
                        <HeadingLevel key={`wp-article-container-${index}`}>
                            {() => {
                                const headerId = getHeaderId(index);
                                const { link, title, imageUrl } = article;
                                return (
                                    <Link
                                        ariaLabel={`Read more about ${title}`}
                                        ariaDescribedBy={headerId}
                                        buyerLinkType="standardLinkNoEffect"
                                        underline="none"
                                        href={link}
                                        target="_blank"
                                        dataTn={`wp-article-link-${index}`}
                                        className={classnames(styles.article, {
                                            [styles.hideArticle]:
                                                !isExpanded &&
                                                index + 1 > INITIAL_VISIBLE_INTERIOR_ARTICLES,
                                        })}
                                        onClick={() => trackArticleClick(article)}
                                    >
                                        <div className={styles.imgElement}>
                                            <LazyImage
                                                offsetVertical={LAZY_IMAGE_VERTICAL_OFFSET}
                                                placeholderClass={styles.lazyLoadPlaceholder}
                                            >
                                                <img
                                                    className={styles.featuredImg}
                                                    src={imageUrl.thumb}
                                                    alt={title}
                                                    srcSet={`
                                                        ${imageUrl.thumb} ${WORDPRESS_THUMB}w,
                                                        ${imageUrl.medium} ${WORDPRESS_MEDIUM}w,
                                                        ${imageUrl.full} ${WORDPRESS_FULL}w
                                                    `}
                                                    sizes={imgSizes}
                                                />
                                            </LazyImage>
                                        </div>
                                        <div className={styles.articleElement}>
                                            <Heading className={styles.header} id={headerId}>
                                                {title}
                                            </Heading>
                                        </div>
                                    </Link>
                                );
                            }}
                        </HeadingLevel>
                    ))}
                </div>
            )}
        </HeadingLevel>
    );
};

export const SbSharedInteriorsArticles = createFragmentContainer(
    SbSharedInteriorsArticlesComponent,
    {
        editorial: graphql`
            fragment SbSharedInteriorsArticles_editorial on WordpressArticlesType {
                articles {
                    publishedDate
                    title
                    imageUrl {
                        thumb
                        medium
                        full
                    }
                    subtitle
                    link
                    source
                }
            }
        `,
    }
);
