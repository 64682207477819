import { FC } from 'react';
import { useFragment, graphql } from 'react-relay';
import PhotoRelatedSearchLinksContainer from 'dibs-interiors-photos/exports/PhotoRelatedSearchLinksContainer';

import type { SbSharedInteriorsPhotoRelatedSearchLinks_interiorPhotos$key } from './__generated__/SbSharedInteriorsPhotoRelatedSearchLinks_interiorPhotos.graphql';

type Props = {
    interiorPhotos: SbSharedInteriorsPhotoRelatedSearchLinks_interiorPhotos$key | null | undefined;
    isPillBackgroundWhite?: boolean;
};

const SbSharedInteriorsPhotoRelatedSearchLinks: FC<Props> = ({
    interiorPhotos: interiorPhotosRef,
    isPillBackgroundWhite,
}) => {
    const interiorPhotos = useFragment(
        graphql`
            fragment SbSharedInteriorsPhotoRelatedSearchLinks_interiorPhotos on SearchBrowseInteriorPhotosModule {
                relatedPhotoSearchLinks {
                    path
                    text
                }
            }
        `,
        interiorPhotosRef
    );

    return (
        <PhotoRelatedSearchLinksContainer
            isPillBackgroundWhite={isPillBackgroundWhite}
            links={interiorPhotos?.relatedPhotoSearchLinks || []}
            trackingLabel="browse"
        />
    );
};

export default SbSharedInteriorsPhotoRelatedSearchLinks;
