import PropTypes from 'prop-types';
import { AdUnit } from './AdUnit';
import styles from './ads.scss';

const BillboardBannerAd = ({ adId, elementId, width = 320, height = 82, lazyLoad = false }) => {
    return (
        <div className={styles.billboardBanner} data-tn="billboard-banner-ad">
            <AdUnit
                adId={adId}
                elementId={elementId}
                width={width}
                height={height}
                lazyLoad={lazyLoad}
            />
        </div>
    );
};

BillboardBannerAd.propTypes = {
    adId: PropTypes.string,
    elementId: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    lazyLoad: PropTypes.bool,
};

export { BillboardBannerAd };
