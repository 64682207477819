import { FC, MouseEvent, KeyboardEvent, ReactNode } from 'react';

type ItemEventType = MouseEvent | KeyboardEvent;

const PhotoOverlayItem: FC<{ children: ReactNode }> = ({ children }) => {
    //prevents from firing parent event
    const handleParentEvent = (e: ItemEventType): void => {
        e.preventDefault();
        e.stopPropagation();
    };
    return (
        <div onClick={handleParentEvent} role="none">
            {children}
        </div>
    );
};

export default PhotoOverlayItem;
