import { trackEvent } from 'dibs-tracking';
import { graphql, readInlineData } from 'react-relay/legacy';

const MIN_PHOTOS_COUNT = 1;

export const showInteriorsModule = (photos?: readonly (ObjectType | null)[] | null): boolean => {
    return (photos || []).length > MIN_PHOTOS_COUNT;
};

export const getPhotoIndex = (newIndex: number, lastIndex: number): number => {
    if (newIndex === -1) {
        newIndex = lastIndex;
    } else if (newIndex > lastIndex) {
        newIndex = 0;
    }
    return newIndex;
};

import { interiorsModuleHelpers_interiorPhotos$key } from './__generated__/interiorsModuleHelpers_interiorPhotos.graphql';

type TrackDesignFeaturePayload = {
    action: string;
    interiorPhotos: interiorsModuleHelpers_interiorPhotos$key | null | undefined;
};

export const trackDesignFeature = ({ action, interiorPhotos }: TrackDesignFeaturePayload): void => {
    const photosData = readInlineData(
        graphql`
            fragment interiorsModuleHelpers_interiorPhotos on SearchBrowseInteriorPhotosModule
            @inline {
                photoSearchParam {
                    name
                }
            }
        `,
        interiorPhotos
    );

    // style/creator
    const paramName = photosData?.photoSearchParam?.name;
    const label = paramName || 'category';

    trackEvent({
        action,
        label,
        category: 'design features',
    });
};

type TrackModuleEventHandler = (payload: Pick<TrackDesignFeaturePayload, 'interiorPhotos'>) => void;

export const trackModuleViewed: TrackModuleEventHandler = ({ interiorPhotos }) => {
    trackDesignFeature({
        action: 'module viewed',
        interiorPhotos,
    });
};

export const trackPhotoClicked: TrackModuleEventHandler = ({ interiorPhotos }) => {
    trackDesignFeature({
        action: 'photo clicked',
        interiorPhotos,
    });
};

export const trackModuleScroll: TrackModuleEventHandler = ({ interiorPhotos }) => {
    trackDesignFeature({
        action: 'interior module scroll',
        interiorPhotos,
    });
};

export const trackViewInteriorCTAClicked: TrackModuleEventHandler = ({ interiorPhotos }) => {
    trackDesignFeature({
        action: 'view interior cta clicked',
        interiorPhotos,
    });
};

export const trackPhotoSave: TrackModuleEventHandler = ({ interiorPhotos }) => {
    trackDesignFeature({
        action: 'photo save',
        interiorPhotos,
    });
};
