import { useEffect, FC } from 'react';
import { graphql, useFragment } from 'react-relay';
import classnames from 'classnames';
import { useIntl, FormattedMessage } from 'dibs-react-intl';
import { useCurrency } from 'dibs-buyer-layout/exports/useCurrency';
import { filterFalsy } from 'dibs-ts-utils/exports/filterFalsy';
import { trackAbTestV2Variant } from 'dibs-buyer-layout/exports/clientABTestV2';

// components
import { SbSharedPriceTile } from '../SbSharedPriceTile/SbSharedPriceTile';
import { SbRespTilesSwiper } from '../SbRespTilesSwiper/SbRespTilesSwiper';
import { useServerVarsContext } from '../../global/ServerVarsContext/ServerVarsContext';
import { SbRespTradeFreeShippingPromoTile } from '../SbRespTradeFreeShippingPromoTile/SbRespTradeFreeShippingPromoTile';

import { SbSharedLink } from '../SbSharedLink/SbSharedLink';
import { getFilterValue } from '../SbSharedRefineMenu/sbSharedRefineMenuHelpers';
import { decode64 } from '../../utils/decode';
import { getSrcsetString } from 'dibs-image-utils/exports/srcSet';
import { SRC_SET_SIZES_RESP_CAROUSEL_TILE } from '../../constants/imageConstants';
import { QP_PERSONALIZED_FILTER_VALUE_SHIPPING_FREE } from '../../constants/queryParamConstants';
import { TRADE_QUICK_FILTERS } from '../../utils/abTestHelper';

import styles from './SbRespPriceTiles.scss';
import dibsCss from 'dibs-css';

import { SbRespPriceTiles_viewer$key } from './__generated__/SbRespPriceTiles_viewer.graphql';
import { SbRespPriceTiles_itemSearch$key } from './__generated__/SbRespPriceTiles_itemSearch.graphql';

const MAX_DISPLAY_PRICE_TILES = 4;

const getPriceTilesForCurrency = <T extends { currency: string | null }>(
    tiles: T[],
    currency: string
): T[] => tiles.filter(tile => tile?.currency === currency);

const viewerFragment = graphql`
    fragment SbRespPriceTiles_viewer on Viewer {
        shippingFilters(uriRef: $uriRef, zipCode: $userZipCode, countryCode: $userCountryCode)
            @include(if: $isClient) {
            name
            values {
                linkReference
            }
        }
        ...SbRespTradeFreeShippingPromoTile_viewer
    }
`;
const itemSearchFragment = graphql`
    fragment SbRespPriceTiles_itemSearch on ItemSearchQueryConnection {
        ...SbSharedPriceTile_itemSearch
        ...SbRespTradeFreeShippingPromoTile_itemSearch
        displayUriRef
        priceTiles(isTrade: $isTrade) {
            currency
            displayName
            image
            url
        }
    }
`;

type Props = {
    viewer: SbRespPriceTiles_viewer$key;
    itemSearch: SbRespPriceTiles_itemSearch$key;
};

export const SbRespPriceTiles: FC<Props> = ({ viewer: viewerRef, itemSearch: itemSearchRef }) => {
    const { isTablet, isTradeQuickFiltersVariant } = useServerVarsContext();
    const { currency } = useCurrency();
    const intl = useIntl();
    const viewer = useFragment(viewerFragment, viewerRef);
    const itemSearch = useFragment(itemSearchFragment, itemSearchRef);
    const displayUriRef = itemSearch?.displayUriRef || '/';
    const priceTiles = (itemSearch?.priceTiles || []).filter(filterFalsy);

    useEffect(() => {
        trackAbTestV2Variant(TRADE_QUICK_FILTERS);
    }, []);

    if (!priceTiles.length) {
        return null;
    }
    const tiles = getPriceTilesForCurrency(priceTiles, currency)
        .filter(priceTile => priceTile.displayName && priceTile.image && priceTile.url)
        .slice(0, MAX_DISPLAY_PRICE_TILES)
        .map(tile => ({
            displayName: tile.displayName || '',
            image: tile.image || '',
            url: tile.url || displayUriRef,
        }));
    //Update unit test after test concludes
    if (isTradeQuickFiltersVariant) {
        const filters = viewer?.shippingFilters || [];

        const freeShippingRegions = getFilterValue(
            filters,
            QP_PERSONALIZED_FILTER_VALUE_SHIPPING_FREE
        );
        tiles.push({
            displayName: intl.formatMessage({
                id: 'sb.SbRespPriceTiles.freeShipping.title',
                defaultMessage: 'Free Shipping',
            }),
            image: 'https://a.1stdibscdn.com/dist/adhoc/trade/free-shipping/free-shipping-tile.jpg',
            url: decode64(freeShippingRegions?.linkReference || '') || displayUriRef,
        });

        return (
            <div className={classnames(dibsCss.flex, dibsCss.justifyBetween, dibsCss.mbLarge)}>
                <SbRespTilesSwiper displayUriRef={displayUriRef} isTablet={isTablet}>
                    {tiles.map(priceTile => {
                        const { displayName, image, url } = priceTile;

                        return (
                            <SbSharedLink
                                className={styles.itemContainer}
                                href={url}
                                onClick={() => {}}
                                key={displayName}
                            >
                                <div
                                    className={classnames(
                                        dibsCss.hAuto,
                                        dibsCss.relative,
                                        dibsCss.wAuto,
                                        dibsCss.maxW33,
                                        dibsCss.flex,
                                        dibsCss.flexShrink0,
                                        dibsCss.mrSmall
                                    )}
                                >
                                    <img
                                        className={classnames(
                                            dibsCss.relative,
                                            dibsCss.insetY0,
                                            dibsCss.insetX0,
                                            dibsCss.mAuto,
                                            dibsCss.maxHXxlarge,
                                            dibsCss.maxWFull
                                        )}
                                        src={image}
                                        alt={displayName}
                                        srcSet={getSrcsetString(image)}
                                        sizes={SRC_SET_SIZES_RESP_CAROUSEL_TILE}
                                    />
                                </div>
                                <div
                                    className={classnames(
                                        dibsCss.sassyFontHeaderSizeXSmallTypeNormal,
                                        dibsCss.flex,
                                        dibsCss.wFull,
                                        dibsCss.itemsCenter,
                                        dibsCss.justifyCenter,
                                        dibsCss.flexGrow,
                                        dibsCss.textLeft,
                                        styles.title
                                    )}
                                >
                                    {displayName}
                                </div>
                            </SbSharedLink>
                        );
                    })}
                </SbRespTilesSwiper>
            </div>
        );
    }

    return (
        <div className={classnames(dibsCss.flex, dibsCss.justifyBetween, dibsCss.mbLarge)}>
            <SbRespTilesSwiper displayUriRef={displayUriRef} isTablet={isTablet}>
                {[
                    ...tiles.map((priceTile, i) => {
                        const { displayName, image, url } = priceTile;
                        const indexLabel = i + 1;
                        return (
                            <SbSharedPriceTile
                                displayName={displayName}
                                hoverMessage={
                                    <FormattedMessage
                                        id="sb.SbRespPriceTiles.shop"
                                        defaultMessage="shop"
                                    />
                                }
                                image={image}
                                isPriceBucket
                                key={`priceTile-${displayName}`}
                                position={indexLabel}
                                promoLabel={`Trade Price Range ${indexLabel}`}
                                itemSearch={itemSearch}
                                url={url}
                            />
                        );
                    }),
                    <SbRespTradeFreeShippingPromoTile
                        key="trade-free-shipping-tile"
                        currency={currency}
                        position={5}
                        itemSearch={itemSearch}
                        viewer={viewer}
                    />,
                ]}
            </SbRespTilesSwiper>
        </div>
    );
};
