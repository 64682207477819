import { trackEcommerce, eventNameConstants } from 'dibs-tracking';

const DEFAULT_FEATURED_TARCKING_OBJ = {
    id: 'L2-result-tiles',
    name: 'L1|Result Tiles',
};

export function trackPromoImpressions({
    promotions,
    position,
    name,
}: {
    promotions: string[];
    position?: number;
    name?: string;
}): void {
    trackEcommerce({
        type: 'promotionImpression',
        eventName: eventNameConstants.EVENT_VIEW_PROMOTION,
        promotions: promotions.map((promotion, index) => ({
            position: position || index + 1,
            creative: promotion,
            id: DEFAULT_FEATURED_TARCKING_OBJ.id,
            name: name || DEFAULT_FEATURED_TARCKING_OBJ.name,
        })),
    });
}

export const getPromotionName = (isPersonalized: boolean): string => {
    return isPersonalized ? 'L1|Just For You' : 'L1|Popular Now';
};

export const trackPromoClick = ({
    promotion,
    position = 1,
    name,
}: {
    promotion: string;
    position?: number;
    name?: string;
}): void => {
    trackEcommerce({
        type: 'promoClick',
        eventName: eventNameConstants.EVENT_SELECT_PROMOTION,
        promotions: [
            {
                position,
                creative: promotion,
                id: DEFAULT_FEATURED_TARCKING_OBJ.id,
                name: name || DEFAULT_FEATURED_TARCKING_OBJ.name,
            },
        ],
    });
};
