/**
 * @generated SignedSource<<40f6be82e0deae8b112e07093edda923>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type VerticalType = "art" | "fashion" | "furniture" | "jewelry" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type SbRespL1Landing_itemSearch$data = {
  readonly l1Module: {
    readonly secondTile: {
      readonly " $fragmentSpreads": FragmentRefs<"SbSharedL1ModuleTile_l1ModuleItemTile">;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"SbSharedL1ModuleBanner_l1Module">;
  } | null;
  readonly meta: {
    readonly header: string | null;
    readonly subHeader: string | null;
  } | null;
  readonly topCategoryL1: VerticalType | null;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedFeaturedDeal_itemSearch">;
  readonly " $fragmentType": "SbRespL1Landing_itemSearch";
};
export type SbRespL1Landing_itemSearch$key = {
  readonly " $data"?: SbRespL1Landing_itemSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbRespL1Landing_itemSearch">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "previewId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbRespL1Landing_itemSearch",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedFeaturedDeal_itemSearch"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "topCategoryL1",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MetaData",
      "kind": "LinkedField",
      "name": "meta",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "header",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "subHeader",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "previewId",
          "variableName": "previewId"
        }
      ],
      "concreteType": "L1Module",
      "kind": "LinkedField",
      "name": "l1Module",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SbSharedL1ModuleBanner_l1Module"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "L1ModuleItemTile",
          "kind": "LinkedField",
          "name": "secondTile",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SbSharedL1ModuleTile_l1ModuleItemTile"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ItemSearchQueryConnection",
  "abstractKey": null
};

(node as any).hash = "30e5ab66ba7da9fcaa1ac6a56e76215f";

export default node;
