/**
 * @generated SignedSource<<338f6e11276ccba406ee06ae6f181699>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbRespComponents_viewer$data = {
  readonly isRelatedCreatorsModuleEnabled: boolean | null;
  readonly " $fragmentSpreads": FragmentRefs<"SbRespInteriorsWordpressArticlesWrapper_viewer" | "SbRespMainContent_viewer" | "SbRespNullSearchResults_viewer" | "SbRespPriceTiles_viewer" | "SbRespTradeOnApprovalPromoBanner_viewer" | "SbSharedRecommendedItems_viewer" | "SbSharedTopTileController_viewer" | "SharedRecentSales_viewer" | "SharedRelatedPages_viewer" | "SharedRespLinksBlock_viewer">;
  readonly " $fragmentType": "SbRespComponents_viewer";
};
export type SbRespComponents_viewer$key = {
  readonly " $data"?: SbRespComponents_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbRespComponents_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "isTrade"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbRespComponents_viewer",
  "selections": [
    {
      "alias": "isRelatedCreatorsModuleEnabled",
      "args": [
        {
          "kind": "Literal",
          "name": "feature",
          "value": "relatedCreatorsModule"
        }
      ],
      "kind": "ScalarField",
      "name": "featureFlag",
      "storageKey": "featureFlag(feature:\"relatedCreatorsModule\")"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SharedRelatedPages_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SharedRespLinksBlock_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbRespNullSearchResults_viewer"
    },
    {
      "condition": "isTrade",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SbRespPriceTiles_viewer"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SbRespTradeOnApprovalPromoBanner_viewer"
        }
      ]
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbRespMainContent_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedTopTileController_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbRespInteriorsWordpressArticlesWrapper_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRecommendedItems_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SharedRecentSales_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "38f7c7c0a0993de856f2afcfbd34018b";

export default node;
