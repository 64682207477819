import { FC } from 'react';

// styles
import styles from './SbRespFeaturedTrending.scss';

export const SbRespFeaturedTrendingLoading: FC = () => (
    <div className={styles.container}>
        <span className={`${styles.title} ${styles.loading}`} />
        <div className={styles.tileContainer}>
            <div className={`${styles.tile} ${styles.loading}`} />
            <div className={`${styles.tile} ${styles.loading}`} />
            <div className={`${styles.tile} ${styles.loading}`} />
        </div>
    </div>
);
