import { trackEvent } from 'dibs-tracking';

export const ATTRIBUTE_SEARCH_SUGGESTION_TRACKING = 'data-static-tracking-search-suggestions';

export function trackSearchSuggestionClick(searchTerm: string, name: string): void {
    trackEvent({
        category: 'search suggestion text',
        action: 'spelling suggestions clicked',
        label: `${searchTerm}|${name}`,
    });
}

type TrackingData = {
    searchTerm: string;
    name: string;
};

export function encodeStaticSearchSuggestionsTrackingData(data: TrackingData): string {
    return JSON.stringify(data);
}

function decodeData(data?: string | null): Partial<TrackingData> {
    return JSON.parse(data || '');
}

export function handleStaticSearchSuggestionsTracking(): void {
    document.querySelectorAll(`[${ATTRIBUTE_SEARCH_SUGGESTION_TRACKING}]`).forEach(element => {
        element.addEventListener('click', () => {
            const { searchTerm, name } = decodeData(
                element.getAttribute(ATTRIBUTE_SEARCH_SUGGESTION_TRACKING)
            );
            if (searchTerm && name) {
                trackSearchSuggestionClick(searchTerm, name);
            }
        });
    });
}
