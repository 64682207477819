import { trackEvent } from 'dibs-tracking';

export const ATTRIBUTE_SEARCH_CORRECTION = 'data-static-tracking-search-correction';
export const ATTRIBUTE_SEARCH_AUTO_CORRECT = 'data-static-tracking-auto-correction';

export const trackSearchCorrectionClick = (term: string, overrideType: string): void =>
    trackEvent({
        label: term,
        category: 'search suggestion text',
        action: `${overrideType} override clicked`,
    });

export const trackAutoCorrectClick = (correctedTerm: string, originalTerm: string): void =>
    trackEvent({
        category: 'search suggestion text',
        action: 'override spelling autocorrect',
        label: `${correctedTerm}|${originalTerm}`,
    });

export const trackAutoCorrectView = (correctedTerm: string, originalTerm: string): void =>
    trackEvent({
        category: 'search suggestion text',
        action: 'spelling autocorrect',
        label: `${correctedTerm}|${originalTerm}`,
    });

export type SearchCorrectionTrackingData = {
    term: string;
    originalTerm: string;
    overrideType: string;
};
export type SearchAutoCorrectionTrackingData = {
    term: string;
    originalTerm: string;
};

export function encodeForceSearchTrackingData<T>(data: T): string {
    return JSON.stringify(data);
}

function decodeData<T>(data?: string | null): Partial<T> {
    return JSON.parse(data || '');
}

export function handleStaticForceSearchClickTracking(): void {
    const searchCorrectionElement = document.querySelector(`[${ATTRIBUTE_SEARCH_CORRECTION}]`);
    const searchAutoCorrectElement = document.querySelector(`[${ATTRIBUTE_SEARCH_AUTO_CORRECT}]`);

    /**
     * Handle `view` tracking, which fires on load
     */
    let trackingData;
    if (searchCorrectionElement) {
        const { term, overrideType } = (trackingData = decodeData<SearchCorrectionTrackingData>(
            searchCorrectionElement.getAttribute(ATTRIBUTE_SEARCH_CORRECTION)
        ));
        searchCorrectionElement?.addEventListener('click', () => {
            if (term && overrideType) {
                trackSearchCorrectionClick(term, overrideType);
            }
        });
    } else if (searchAutoCorrectElement) {
        const { term, originalTerm } = (trackingData = decodeData<SearchAutoCorrectionTrackingData>(
            searchAutoCorrectElement.getAttribute(ATTRIBUTE_SEARCH_CORRECTION) || ''
        ));
        searchAutoCorrectElement?.addEventListener('click', () => {
            if (term && originalTerm) {
                trackAutoCorrectClick(term, originalTerm);
            }
        });
    }
    const { term, originalTerm } = trackingData || {};
    if (term && originalTerm) {
        trackAutoCorrectView(term, originalTerm);
    }
}
