/**
 * @generated SignedSource<<8c9175391090d04b48604ac49ddef273>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbRespInteriorsPhotoCarousel_interiorPhotos$data = {
  readonly totalResults: number | null;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedInteriorsBrowseMore_interiorPhotos" | "SbSharedInteriorsPhotoRelatedSearchLinks_interiorPhotos">;
  readonly " $fragmentType": "SbRespInteriorsPhotoCarousel_interiorPhotos";
};
export type SbRespInteriorsPhotoCarousel_interiorPhotos$key = {
  readonly " $data"?: SbRespInteriorsPhotoCarousel_interiorPhotos$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbRespInteriorsPhotoCarousel_interiorPhotos">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbRespInteriorsPhotoCarousel_interiorPhotos",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedInteriorsPhotoRelatedSearchLinks_interiorPhotos"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedInteriorsBrowseMore_interiorPhotos"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalResults",
      "storageKey": null
    }
  ],
  "type": "SearchBrowseInteriorPhotosModule",
  "abstractKey": null
};

(node as any).hash = "a5ece9c840eddc058f7ed6fd96e53a66";

export default node;
