/**
 * @generated SignedSource<<30fc387a898bd36def21ea4ef409111a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbRespPriceTiles_itemSearch$data = {
  readonly displayUriRef: string | null;
  readonly priceTiles: ReadonlyArray<{
    readonly currency: string | null;
    readonly displayName: string | null;
    readonly image: string | null;
    readonly url: string | null;
  } | null> | null;
  readonly " $fragmentSpreads": FragmentRefs<"SbRespTradeFreeShippingPromoTile_itemSearch" | "SbSharedPriceTile_itemSearch">;
  readonly " $fragmentType": "SbRespPriceTiles_itemSearch";
};
export type SbRespPriceTiles_itemSearch$key = {
  readonly " $data"?: SbRespPriceTiles_itemSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbRespPriceTiles_itemSearch">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "isTrade"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbRespPriceTiles_itemSearch",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedPriceTile_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbRespTradeFreeShippingPromoTile_itemSearch"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayUriRef",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "isTrade",
          "variableName": "isTrade"
        }
      ],
      "concreteType": "SearchBrowsePriceTile",
      "kind": "LinkedField",
      "name": "priceTiles",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "currency",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "image",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ItemSearchQueryConnection",
  "abstractKey": null
};

(node as any).hash = "75c73df7d2d5ac0e8d987716a7fa52fe";

export default node;
