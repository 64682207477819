import { FC, lazy, Suspense } from 'react';
import { useSelector } from 'react-redux';

// components
import { SbRespFeaturedTrendingLoading } from './SbRespFeaturedTrendingLoading';

const SbRespFeaturedTrending = lazy(() => import('./SbRespFeaturedTrending'));

type Props = {
    vertical: string | null;
    leadingHeader?: string | null;
};

type RelayVariables = {
    isClient: boolean;
    userId: string;
};

type ReduxStoreType = {
    relayVariables: {
        variables: RelayVariables;
    };
};

export const SbRespFeaturedTrendingLazy: FC<Props> = ({ vertical, leadingHeader }) => {
    const { isClient, userId } = useSelector<ReduxStoreType, RelayVariables>(
        state => state?.relayVariables?.variables || {}
    );

    if (!isClient) {
        return <SbRespFeaturedTrendingLoading />;
    }

    return (
        <Suspense fallback={<SbRespFeaturedTrendingLoading />}>
            <SbRespFeaturedTrending
                userId={userId}
                vertical={vertical}
                leadingHeader={leadingHeader}
            />
        </Suspense>
    );
};
