import { localStorage } from 'dibs-browser-storage';

export type BackLinkPayload = {
    displayName: string;
    linkUrl: string;
};
export const SB_PHOTOS_BACK_LINK = 'SB_PHOTOS_BACK_LINK';

export const setSbPhotosBackLink = (payload: BackLinkPayload): void => {
    localStorage.setItem(SB_PHOTOS_BACK_LINK, payload);
};

export const clearSbPhotosBackLink = (): void => {
    localStorage.deleteItem(SB_PHOTOS_BACK_LINK);
};

export const getSbPhotosBackLink = (): JsonType => localStorage.getItem(SB_PHOTOS_BACK_LINK);
