/**
 * @generated SignedSource<<01c2d2fd56680208192a39906e260a6e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbSharedL1ModuleTile_l1ModuleItemTile$data = {
  readonly dek: string | null;
  readonly header: string | null;
  readonly linkData: {
    readonly " $fragmentSpreads": FragmentRefs<"SeoLink_linkData">;
  };
  readonly mainImage: string | null;
  readonly " $fragmentType": "SbSharedL1ModuleTile_l1ModuleItemTile";
};
export type SbSharedL1ModuleTile_l1ModuleItemTile$key = {
  readonly " $data"?: SbSharedL1ModuleTile_l1ModuleItemTile$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedL1ModuleTile_l1ModuleItemTile">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbSharedL1ModuleTile_l1ModuleItemTile",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dek",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "header",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LinkData",
      "kind": "LinkedField",
      "name": "linkData",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SeoLink_linkData"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mainImage",
      "storageKey": null
    }
  ],
  "type": "L1ModuleItemTile",
  "abstractKey": null
};

(node as any).hash = "d6c76948fa2fa7189d26b0278aee72e1";

export default node;
